<template>
  <b-container>
    <b-row class="mt-5 mb-4">
      <b-col>
        <h1 v-if="girl.name" class="display-1 text-center text-capitalize script-font">{{ girl.name }}</h1>
      </b-col>
    </b-row>
    <b-row class="d-none d-lg-block">
      <b-col>
        <img v-if="anzeigebild" :alt="girl.name" :src="anzeigebild" class="img-fluid w-100 rounded">
      </b-col>
    </b-row>

    <b-row class="mt-5 mb-4">
      <b-col>
        <h2 v-if="girl.sprachen" class="text-center text-capitalize"><span v-if="lang === 'de'">{{ girl.sprachen }}</span><span v-if="lang === 'en'">{{ girl.sprachen_en }}</span></h2>
      </b-col>
    </b-row>

    <b-row class="mt-5 mb-4 services" v-if="girl.services">
      <b-col>
        <h3 class="h4 text-center text-md-left"><span v-if="lang === 'de'">Services</span><span v-if="lang === 'en'">Services</span></h3>
        <b-badge style="font-size: 14px;" class="mr-2 mb-2" pill variant="secondary" v-for="service in girl.services" :key="service">{{ service }}</b-badge>
      </b-col>
    </b-row>

    <b-row class="mt-5 mb-4">
      <b-col md=6>
        <h3 class="h4 text-center text-md-left"><span v-if="lang === 'de'">Informationen</span><span
            v-if="lang === 'en'">Informations</span></h3>
        <b-list-group tag="ul" variant="flush">
          <b-list-group-item v-if="girl.name" tag="li">
            <span class="lead list-group-item-name"><span v-if="lang === 'de'">Name:</span><span v-if="lang === 'en'">Name:</span></span>
            <div class="lead float-right text-capitalize">{{ girl.name }}</div>
          </b-list-group-item>
          <b-list-group-item v-if="girl.alter" tag="li">
            <span class="lead list-group-item-name"><span v-if="lang === 'de'">Alter:</span><span v-if="lang === 'en'">Age:</span></span>
            <div class="lead float-right text-capitalize">{{ girl.alter }}</div>
          </b-list-group-item>
          <b-list-group-item v-if="girl.groesse" tag="li">
            <span class="lead list-group-item-name"><span v-if="lang === 'de'">Größe:</span><span v-if="lang === 'en'">Size:</span></span>
            <div class="lead float-right text-capitalize">{{ girl.groesse }} <span
                class="text-lowercase">cm</span></div>
          </b-list-group-item>
          <b-list-group-item v-if="girl.gewicht" tag="li">
            <span class="lead list-group-item-name"><span v-if="lang === 'de'">Gewicht:</span><span
                v-if="lang === 'en'">Weight:</span></span>
            <div class="lead float-right text-capitalize">{{ girl.gewicht }} <span
                class="text-lowercase">kg</span></div>
          </b-list-group-item>
          <b-list-group-item v-if="girl.oberweite" tag="li">
            <span class="lead list-group-item-name"><span v-if="lang === 'de'">Brustumfang:</span><span
                v-if="lang === 'en'">Chest circumference:</span></span>
            <div class="lead float-right text-capitalize">{{ girl.oberweite }}</div>
          </b-list-group-item>
          <b-list-group-item v-if="girl.haarfarbe" tag="li">
            <span class="lead list-group-item-name"><span v-if="lang === 'de'">Haarfarbe:</span><span
                v-if="lang === 'en'">Haircolor:</span></span>
            <div class="lead float-right text-capitalize"><span v-if="lang === 'de'">{{ girl.haarfarbe }}</span><span v-if="lang === 'en'">{{ girl.haarfarbe_en }}</span></div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col class="mt-5 mt-md-0" md=6>
        <h3 class="h4 text-center text-md-left"><span v-if="lang === 'de'">Verfügbarkeiten</span><span
            v-if="lang === 'en'">Availabilities</span></h3>
        <b-list-group class="uhrzeiten" tag="ul" variant="flush">
          <b-list-group-item tag="li" v-bind:class="{ active: new Date().getDay() === 1 }">
            <span class="lead list-group-item-name text-capitalize"><span v-if="lang === 'de'">Montag:</span><span
                v-if="lang === 'en'">Monday:</span></span>
            <div class="lead float-right text-capitalize">{{ zeiten[0] }} <span v-if="lang === 'de'">Uhr</span><span
                v-if="lang === 'en'">o'clock</span></div>
          </b-list-group-item>
          <b-list-group-item tag="li" v-bind:class="{ active: new Date().getDay() === 2 }">
            <span class="lead list-group-item-name text-capitalize"><span v-if="lang === 'de'">Dienstag:</span><span
                v-if="lang === 'en'">Tuesday:</span></span>
            <div class="lead float-right text-capitalize">{{ zeiten[1] }} <span v-if="lang === 'de'">Uhr</span><span
                v-if="lang === 'en'">o'clock</span></div>
          </b-list-group-item>
          <b-list-group-item tag="li" v-bind:class="{ active: new Date().getDay() === 3 }">
            <span class="lead list-group-item-name text-capitalize"><span v-if="lang === 'de'">Mittwoch:</span><span
                v-if="lang === 'en'">Wednesday:</span></span>
            <div class="lead float-right text-capitalize">{{ zeiten[2] }} <span v-if="lang === 'de'">Uhr</span><span
                v-if="lang === 'en'">o'clock</span></div>
          </b-list-group-item>
          <b-list-group-item tag="li" v-bind:class="{ active: new Date().getDay() === 4 }">
            <span class="lead list-group-item-name text-capitalize"><span v-if="lang === 'de'">Donnerstag:</span><span
                v-if="lang === 'en'">Thurday:</span></span>
            <div class="lead float-right text-capitalize">{{ zeiten[3] }} <span v-if="lang === 'de'">Uhr</span><span
                v-if="lang === 'en'">o'clock</span></div>
          </b-list-group-item>
          <b-list-group-item tag="li" v-bind:class="{ active: new Date().getDay() === 5 }">
            <span class="lead list-group-item-name text-capitalize"><span v-if="lang === 'de'">Freitag:</span><span
                v-if="lang === 'en'">Friday:</span></span>
            <div class="lead float-right text-capitalize">{{ zeiten[4] }} <span v-if="lang === 'de'">Uhr</span><span
                v-if="lang === 'en'">o'clock</span></div>
          </b-list-group-item>
          <b-list-group-item tag="li" v-bind:class="{ active: new Date().getDay() === 6 }">
            <span class="lead list-group-item-name text-capitalize"><span v-if="lang === 'de'">Samstag:</span><span
                v-if="lang === 'en'">Saturday:</span></span>
            <div class="lead float-right text-capitalize">{{ zeiten[5] }} <span v-if="lang === 'de'">Uhr</span><span
                v-if="lang === 'en'">o'clock</span></div>
          </b-list-group-item>
          <b-list-group-item tag="li" v-bind:class="{ active: new Date().getDay() === 7 }">
            <span class="lead list-group-item-name text-capitalize"><span v-if="lang === 'de'">Sonntag:</span><span
                v-if="lang === 'en'">Sunday:</span></span>
            <div class="lead float-right text-capitalize">{{ zeiten[6] }} <span v-if="lang === 'de'">Uhr</span><span
                v-if="lang === 'en'">o'clock</span></div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="alert alert-dark" v-if="lang === 'de'">
          <strong>Hinweis!</strong>
          <p>Die Massage Girls in der Relaxinsel sind selbstständig tätig und entscheiden somit auch selbst
            über die Dienstleistungen, die sie anbieten und die Preise für diese. Deshalb solltest Du
            grundsätzlich jeden Wunsch mit dem Girl Deiner Wahl direkt besprechen, da jedes einen anderen
            Service anbietet. Auch die Bezahlung ist direkt mit dem Massage Girl abzuwickeln.</p>
          <p>Alle Vereinbarungen bezüglich Leistungen, Höhe der Vergütung, die Form der Bezahlung und mögliche
            Reklamationen für sexuelle Dienstleistungen finden zwischen Dir und dem von Dir gebuchten
            Massage Girl statt. Deine Vereinbarungen mit dem Massage Girl Deiner Wahl begründen kein
            Vertragsverhältnis und keine geschäftliche Beziehung zwischen Dir und der Relaxinsel.</p>
        </div>
        <div class="alert alert-dark" v-if="lang === 'en'">
          <strong>Notice!</strong>
          <p>The massage girls in Relaxinsel are self-employed and therefore decide themselves about the services
            they
            offer and the prices for them. Therefore, you should basically discuss any request with the girl of
            your
            choice directly, since each offers a different service. Also the payment is to be handled directly
            with the
            massage girl.</p>
          <p>All agreements regarding services, amount of payment, form of payment and possible complaints for
            sexual
            services take place between you and the massage girl booked by you. Your agreements with the massage
            girl of
            your choice do not establish a contractual relationship and no business relationship between you and
            the
            Relaxinsel.</p>
        </div>
      </b-col>
    </b-row>
    <b-row class="beschreibung mt-5 mb-4" v-if="text">
      <b-col>
        <div class="beschreibung" v-if="text.length > 0" v-html="text"></div>
      </b-col>
    </b-row>
    <b-row class="mt-5 mb-4">
      <b-col>
        <img v-for="bild in bilder" :key="bild" :src="bild" class="img-fluid w-100 mb-3 rounded">
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'GirlLayout',
  computed: {
    lang: function () {
      return this.$store.state.lang
    },
    girl: function () {
      return this.$store.state.girl
    },
    anzeigebild: function () {
      return this.$store.state.girl.anzeigebild.sizes.large
    },
    name: function () {
      return this.$store.state.name
    },
    bilder: function () {
      const temp = []
      for (const bild in this.$store.state.girl.bilder) {
        if (this.$store.state.girl.bilder[bild]) {
          temp.push(this.$store.state.girl.bilder[bild].sizes.large)
        }
      }
      return temp
    },
    zeiten: function () {
      const temp = []
      for (const acfTag in this.$store.state.girl.zeiten) {
        if (this.$store.state.girl.zeiten[acfTag]) {
          let start
          let end

          if (typeof this.$store.state.girl.zeiten[acfTag][acfTag + '_start'] !== 'undefined') {
            start = this.$store.state.girl.zeiten[acfTag][acfTag + '_start']
          }

          if (typeof this.$store.state.girl.zeiten[acfTag][acfTag + '_ende'] !== 'undefined') {
            end = this.$store.state.girl.zeiten[acfTag][acfTag + '_ende']
          }

          if (start) start = (start.length < 1) ? '-' : start
          if (end) end = (end.length < 1) ? '-' : end
          temp.push(start + ' - ' + end)
        }
      }
      return temp
    },
    text: function () {
      const e = document.createElement('div')
      e.innerHTML = this.$store.state.girl.infos
      e.querySelectorAll('p').forEach(el => el.classList.add('lead'))
      return e.innerHTML
    },
    text_en: function () {
      const e = document.createElement('div')
      e.innerHTML = this.$store.state.girl.infos_en
      e.querySelectorAll('p').forEach(el => el.classList.add('lead'))
      return e.innerHTML
    },
    yes: function () {
      return (this.$store.state.lang === 'en') ? 'Yes' : 'Ja'
    },
    no: function () {
      return (this.$store.state.lang === 'en') ? 'No' : 'Nein'
    }
  },
  created () {
    const uri = window.location.search.substring(1)
    const lang = new URLSearchParams(uri).get('lang')
    if (lang) {
      this.$store.dispatch('getLanguage', lang)
    } else {
      console.error('Not found any language. Set default to german.')
    }
    this.$store.dispatch('getName')
    this.$store.dispatch('loadGirls')
  }
}
</script>

<style lang="scss" scoped>
.script-font {
  font-family: 'Mr De Haviland', cursive;
  color: #d1af62;
}

.list-group-item.active {
  background-color: #d1af62;
  border-color: darken(#d1af62, 10%)
}

.alert-dark {
  background-color: #303030;
  border-color: #444;
  color: white;
}
</style>
