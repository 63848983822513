import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  state: {
    name: null,
    lang: 'de',
    girl: {
      aktiv: null,
      alter: null,
      anwesenheit: null,
      anzeigebild: {
        ID: null,
        alt: null,
        author: null,
        caption: null,
        date: null,
        description: null,
        filename: null,
        filesize: null,
        height: null,
        icon: null,
        id: null,
        link: null,
        menu_order: null,
        mime_type: null,
        modified: null,
        name: null,
        sizes: {
          '1536x1536': null,
          '1536x1536-height': null,
          '1536x1536-width': null,
          '2048x2048': '',
          '2048x2048-height': null,
          '2048x2048-width': null,
          'gallery-image': '',
          'gallery-image-height': null,
          'gallery-image-width': null,
          large: '',
          'large-height': null,
          'large-width': null,
          medium: '',
          'medium-height': null,
          'medium-width': null,
          medium_large: null,
          'medium_large-height': null,
          'medium_large-width': null,
          thumbnail: null
        },
        status: null,
        subtype: null,
        title: null
      },
      bilder: {
        bilder1: null,
        bilder2: null,
        bilder3: null,
        bilder4: null,
        bilder5: null,
        bilder6: null,
        bilder7: null,
        bilder8: null,
        bilder9: null
      },
      services: null,
      gewicht: null,
      groesse: null,
      haarfarbe: null,
      haarfarbe_en: null,
      infos: null,
      infos_en: null,
      name: null,
      neu: null,
      oberweite: null,
      sprachen: null,
      sprachen_en: null,
      zeiten: {
        dienstag: {
          dienstag_end: null,
          dienstag_start: null
        },
        donnerstag: {
          donnerstag_ende: null,
          donnerstag_start: null
        },
        freitag: {
          freitag_ende: null,
          freitag_start: null
        },
        mittwoch: {
          mittwoch_end: null,
          mittwoch_start: null
        },
        montag: {
          montag_end: null,
          montag_start: null
        },
        samstag: {
          samstag_ende: null,
          samstag_start: null
        },
        sonntag: {
          sonntag_ende: null,
          sonntag_start: null
        }

      }

    }
  },
  actions: {
    getLanguage ({ commit }, language) {
      commit('SAVE_LANGUAGE', language)
    },
    getName ({ commit }) {
      commit('SAVE_NAME', window.location.host.split('.')[0].includes('localhost') ? 'britney' : window.location.host.split('.')[0])
    },
    loadGirls ({ commit }) {
      const options = {
        method: 'GET',
        url: 'https://relaxinsel.de/wp-json/wp/v2/ankowski_girls/',
        headers: {
          Authorization:
            'Basic Y2hyaXM6QW5rb3dza2llZGMuMTIz'
        }
      }
      Vue.axios.request(options).then(result => {
        result.data.forEach(entry => {
          if (entry.acf.name.toLowerCase() === this.state.name.toLowerCase()) {
            commit('SAVE_USERS', entry.acf)
          }
        })
      }).catch(error => {
        throw new Error(`API ${error}`)
      })
    }
  },
  mutations: {
    SAVE_LANGUAGE (state, language) {
      state.lang = language
    },
    SAVE_NAME (state, name) {
      state.name = name
    },
    SAVE_USERS (state, girl) {
      state.girl = girl
    }
  }
})
