<template>
  <div id="app">
    <GirlLayout/>
  </div>
</template>

<script>
import GirlLayout from './components/GirlLayout.vue'

export default {
  name: 'App',
  components: {
    GirlLayout
  }
}
</script>
